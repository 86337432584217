<template>
  <div class="formPage" :class="locale">
    <div class="formContainer position-relative" :class="locale">
      <div class="formContainer__badge" :class="locale">
        <img
          class="img-fluid"
          width="160"
          :src="require('../../assets/images/form/sotherma_blue1.png')"
        />
      </div>
      <div class="formContainer__contacter" :class="locale">
        <p class="text-white text-uppercase">{{ $t("contacter.title") }}</p>
      </div>
      <div class="pt-sm-5 pt-2">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <div class="container">
              <div class="row">
                <div
                  class="col-sm-6 col-12 formContainer__inputCol "
                  :class="locale"
                  v-for="{
                    name,
                    label,
                    component,
                    required,
                    ...attrs
                  } in formobjs"
                  :key="name"
                >
                  <component
                    :is="component"
                    :name="name"
                    :label="label"
                    v-bind="attrs"
                    :required="required"
                    @update-field="updateField"
                  >
                    <template v-if="attrs.children">
                      <option
                        v-for="({ value, text, ...rest }, i) in attrs.children"
                        v-bind="rest"
                        :key="i"
                        :value="value"
                      >
                        {{ text }}
                      </option>
                    </template>
                  </component>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-sm-6 col-12 formContainer__inputCol"
                  :class="locale"
                >
                  <SelectInput
                    :name="age.name"
                    :label="age.label"
                    :rules="age.rules"
                    :id="age.name"
                    @update-field="updateField"
                    class="formContainer__select age"
                    :class="locale"
                  >
                    <option
                      v-for="(option, i) in age.options"
                      :key="i"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </SelectInput>
                </div>
                <div
                  class="col-sm-6 col-12 formContainer__inputCol textareaCol"
                  :class="locale"
                >
                  <TextArea
                    :name="comments.name"
                    :rules="comments.rules"
                    :label="comments.label"
                    :initialValue="comments.initialValue"
                    :id="comments.name"
                    :required="comments.required"
                    @update-field="updateField"
                  />
                </div>

                <div
                  class="
                    col-sm-6 col-12
                    formContainer__inputCol formContainer__twinInputs
                  "
                  :class="locale"
                >
                  <div class="mb-4">
                    <SelectInput
                      :name="profession.name"
                      :label="profession.label"
                      :id="profession.name"
                      @update-field="updateField"
                      :rules="profession.rules"
                      :required="profession.required"
                    >
                      <option
                        v-for="(option, i) in profession.options"
                        :key="i"
                        :value="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </SelectInput>
                  </div>
                  <TextInput
                    :name="ville.name"
                    :label="ville.label"
                    :id="ville.name"
                    :rules="ville.rules"
                    @update-field="updateField"
                    :required="ville.required"
                  />
                </div>
                <div class="col-sm-6 col-12 text-center mt-5">
                  <Button
                    :text="$t('contacter.contact_send')"
                    :disabled="formSending"
                  />
                </div>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
      <h4
        class="text-center mt-0 pt-0"
        v-if="formSent"
        style="color: green; letter-spacing: 2px"
      >
        <b>{{ $t("contacter.success") }}</b>
      </h4>
      <!-- <p
        class="
          text-secondary
          ml-sm-4 ml-0
          mt-sm-0 mt-4
          text-sm-left text-center
        "
      >
        * Champs requis.
      </p> -->
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import veeValidateExtends from "../mixins/rules";
import TextInput from "./input.vue";
import SelectInput from "./select.vue";
import TextArea from "./TextArea.vue";
import Button from "./Button.vue";
[...veeValidateExtends()];

export default {
  name: "BootstrapForm",
  props: {
    stacked: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    Button,
    ValidationObserver,
    TextInput,
    SelectInput,
    TextArea,
  },
  data: () => {
    return {
      formData: {},
      loading: false,
      formSending: false,
      formSent: false,
      errorMessage: "",
      formobjs: [],
      age: {},
      comments: {},
      profession: {},
      ville: {},
    };
  },
  mounted() {
    this.formobjs = this.$i18n.t("contacter.formobjs");
    this.age = this.$i18n.t("contacter.age");
    this.comments = this.$i18n.t("contacter.comments");
    this.profession = this.$i18n.t("contacter.profession");
    this.ville = this.$i18n.t("contacter.ville");
  },

  watch: {
    "$i18n.locale"(value) {
      this.formobjs = this.$i18n.t("contacter.formobjs");
      this.age = this.$i18n.t("contacter.age");
      this.comments = this.$i18n.t("contacter.comments");
      this.profession = this.$i18n.t("contacter.profession");
      this.ville = this.$i18n.t("contacter.ville");
    },
  },
  computed: {
    formFields() {
      return this.$store.state.formSchema;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    updateField(field) {
      const { name, newVal } = field;
      if (!newVal && !newVal.length) return delete this.formData[name];
      this.formData[name] = newVal;
    },

    async onSubmit() {
      const server = "/api";
      const endpoint = "/contact/contact-us";
      try {
        this.formSending = true;
        const rawResponse = await fetch(`${server}${endpoint}`, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.formData),
        });
        this.formSent = true;
        this.formSending = false;
        setTimeout(function() {
          location.reload();
        }, 750);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
